/* Can't style the html tag with theme-ui, so put styles in here */

html {
  height: 100%;
}

div.fadeMe {
  
  background: radial-gradient(
    circle farthest-corner at top left,
    #0088b233 0%,
    transparent 50%
  );
  
  pointer-events: none;

  width: 100%;
  height: 100%;
  
  top: 0;
  left: 0;
  position: fixed; 
  z-index: 44;
  
}

@media only screen and (max-width: 600px) {
  div.fadeMe {
    background: radial-gradient(
    circle farthest-corner at top left,
    #0088b225 0%,
    transparent 50%
  );
  
  pointer-events: none;
    
  width: 100%;
  height: 100%;
  
  top: 0;
  left: 0;
  position: fixed; 
  z-index: 4;


  
  }
}

